<template>
  <v-card
    class="tab-content"
    elevation="0"
    :disabled="isCardDisabled"
    v-disable-children="isCardDisabled"
  >
    <v-row class="mt-0">
      <v-col cols="6" class="py-0">
        <h3 class="mt-0">Личные данные</h3>

        <v-autocomplete
          class="required"
          v-model="form.nationality"
          hide-no-data
          :items="nationalities"
          :item-text="item => item.name"
          label="Гражданство"
          placeholder="Выберите из списка"
          return-object
          :error-messages="errorMessages.nationality"
          @input="$v.form.nationality.$touch(); markRequiredFields()"
          @blur="$v.form.nationality.$touch()"
        />

        <v-autocomplete
          class="required"
          v-model="form.cycleId"
          hide-no-data
          :items="cycles"
          item-value="cycleId"
          item-text="cycleNumWithMessage"
          label="Номер цикла"
          placeholder="Выберите из списка"
          :error-messages="errorMessages.cycleId"
          @input="$v.form.cycleId.$touch()"
          @blur="$v.form.cycleId.$touch()"
        />

        <v-text-field
          class="required"
          label="Фамилия"
          v-model="form.lastName"
          :error-messages="errorMessages.lastName"
          @input="$v.form.lastName.$touch()"
          @blur="$v.form.lastName.$touch()"
        />

        <v-text-field
          class="required"
          label="Имя"
          v-model="form.firstName"
          :error-messages="errorMessages.firstName"
          @input="$v.form.firstName.$touch()"
          @blur="$v.form.firstName.$touch()"
        />

        <v-text-field
          label="Отчество"
          v-model="form.middleName"
          :error-messages="errorMessages.middleName"
          @input="$v.form.middleName.$touch()"
          @blur="$v.form.middleName.$touch()"
        />

        <v-text-field
          class="required"
          label="Фамилия в родительном падеже"
          v-model="form.lastNameGen"
          :error-messages="errorMessages.lastNameGen"
          @input="$v.form.lastNameGen.$touch()"
          @blur="$v.form.lastNameGen.$touch()"
        />

        <v-text-field
          class="required"
          label="Имя в родительном падеже"
          v-model="form.firstNameGen"
          :error-messages="errorMessages.firstNameGen"
          @input="$v.form.firstNameGen.$touch()"
          @blur="$v.form.firstNameGen.$touch()"
        />

        <v-text-field
          label="Отчество в родительном падеже"
          v-model="form.middleNameGen"
          :error-messages="errorMessages.middleNameGen"
          @input="$v.form.middleNameGen.$touch()"
          @blur="$v.form.middleNameGen.$touch()"
        />

        <v-autocomplete
          v-model="form.gender"
          class="required"
          hide-no-data
          :items="genders"
          :item-text="item => item.name"
          label="Пол"
          placeholder="Выберите из списка"
          return-object
          :error-messages="errorMessages.gender"
          @input="$v.form.gender.$touch()"
          @blur="$v.form.gender.$touch()"
        />

        <v-text-field
          :class="{'required': form.isRussian}"
          label="СНИЛС"
          v-model="form.snils"
          v-mask="'###-###-### ##'"
          :error-messages="errorMessages.snils"
          @input="$v.form.snils.$touch()"
          @blur="$v.form.snils.$touch()"
        />

        <v-text-field
          class="required"
          label="Дата рождения"
          v-model="form.birthDate"
          v-mask="'##.##.####'"
          :error-messages="errorMessages.birthDate"
          @input="$v.form.birthDate.$touch()"
          @blur="$v.form.birthDate.$touch()"
        />

        <h3>Паспортные данные</h3>

        <v-autocomplete
          class="required"
          v-model="form.identityCardType"
          hide-no-data
          :items="identityCards"
          :item-text="item => item.identityCardNameFull"
          label="Документ, удостоверяющий личность"
          placeholder="Выберите из списка"
          return-object
          :error-messages="errorMessages.identityCardType"
          @input="$v.form.identityCardType.$touch()"
          @blur="$v.form.identityCardType.$touch()"
        />

        <v-text-field
          v-if="form.isMigrationIdentityCard"
          class="required"
          label="Идентификатор УФМС"
          v-model="form.migrationServiceIdentifier"
          :error-messages="errorMessages.migrationServiceIdentifier"
          @input="$v.form.migrationServiceIdentifier.$touch()"
          @blur="$v.form.migrationServiceIdentifier.$touch()"
        />

        <v-text-field
          :class="{'required': form.isPassportIdentityCard}"
          label="Серия"
          v-model="form.identityCardSeries"
          v-mask="form.isPassportIdentityCard ? '####' : customMask(20)"
          :error-messages="errorMessages.identityCardSeries"
          @input="$v.form.identityCardSeries.$touch()"
          @blur="$v.form.identityCardSeries.$touch()"
        />

        <v-text-field
          class="required"
          label="Номер"
          v-model="form.identityCardNumber"
          v-mask="form.isPassportIdentityCard ? '######' : customMask(30)"
          :error-messages="errorMessages.identityCardNumber"
          @input="$v.form.identityCardNumber.$touch()"
          @blur="$v.form.identityCardNumber.$touch()"
        />

        <v-text-field
          class="required"
          label="Дата выдачи"
          v-model="form.identityCardIssueDate"
          v-mask="'##.##.####'"
          :error-messages="errorMessages.identityCardIssueDate"
          @input="$v.form.identityCardIssueDate.$touch()"
          @blur="$v.form.identityCardIssueDate.$touch()"
        />

        <v-textarea
          v-model="form.identityCardIssueBy"
          label="Кем выдан"
          no-resize
          auto-grow
          rows="1"
          :error-messages="errorMessages.identityCardIssueBy"
          @input="$v.form.identityCardIssueBy.$touch()"
          @blur="$v.form.identityCardIssueBy.$touch()"
        />
      </v-col>

      <v-col cols="6" class="py-0">
        <h3 class="mt-0">Адрес по прописке</h3>

        <v-text-field
          label="Индекс"
          v-model="form.zipCode"
          v-mask="'######'"
        />

        <v-autocomplete
          class="required"
          v-model="form.countryRegion"
          hide-no-data
          :items="nationalities"
          :item-text="item => item.name"
          label="Страна"
          placeholder="Выберите из списка"
          return-object
          :error-messages="errorMessages.countryRegion"
          @input="$v.form.countryRegion.$touch()"
          @blur="$v.form.countryRegion.$touch()"
        />

        <v-autocomplete
          v-if="form.isRusCountryRegion"
          class="required"
          v-model="form.state"
          hide-no-data
          :items="addressStates"
          :item-text="item => item.name"
          label="Регион"
          placeholder="Выберите из списка"
          return-object
          :error-messages="errorMessages.state"
          @input="$v.form.state.$touch(); getCities($event, 'state')"
          @blur="$v.form.state.$touch()"
        />

        <v-autocomplete
          v-if="form.state"
          :loading="citiesLoading"
          v-model="form.addressTown"
          autocomplete="new-password"
          hide-no-data
          :items="cities"
          item-text="name"
          label="Город"
          placeholder="Выберите из списка"
          return-object
        />

        <v-textarea
          v-model="form.address"
          class="required"
          label="Адрес"
          no-resize
          auto-grow
          rows="1"
          :error-messages="errorMessages.address"
          @input="$v.form.address.$touch()"
          @blur="$v.form.address.$touch()"
        />

        <h3 class="home-address-title">Адрес проживания</h3>

        <v-checkbox
          class="copy-checkbox"
          label="Адрес проживания совпадает с адресом по прописке"
          hide-details
          @change="form.copyRegAddress($event)"
        />

        <v-text-field
          label="Индекс"
          v-model="form.homeZipCode"
          v-mask="'######'"
        />

        <v-autocomplete
          class="required"
          v-model="form.homeCountryRegion"
          hide-no-data
          :items="nationalities"
          :item-text="item => item.name"
          label="Страна"
          placeholder="Выберите из списка"
          return-object
          :error-messages="errorMessages.homeCountryRegion"
          @input="$v.form.homeCountryRegion.$touch()"
          @blur="$v.form.homeCountryRegion.$touch()"
        />

        <v-autocomplete
          v-if="form.isRusHomeCountryRegion"
          class="required"
          v-model="form.homeState"
          hide-no-data
          :items="addressStates"
          :item-text="item => item.name"
          label="Регион"
          placeholder="Выберите из списка"
          return-object
          :error-messages="errorMessages.homeState"
          @input="$v.form.homeState.$touch(); getCities($event, 'homeState')"
          @blur="$v.form.homeState.$touch()"
        />

        <v-autocomplete
          v-if="form.homeState"
          :loading="homeCitiesLoading"
          v-model="form.homeAddressTown"
          autocomplete="new-password"
          hide-no-data
          :items="homeCities"
          item-text="name"
          label="Город"
          placeholder="Выберите из списка"
          return-object
        />

        <v-textarea
          v-model="form.homeAddress"
          label="Адрес"
          no-resize
          auto-grow
          rows="1"
          :error-messages="errorMessages.homeAddress"
          @input="$v.form.homeAddress.$touch()"
          @blur="$v.form.homeAddress.$touch()"
        />

        <h3 class="accent--text contacts-title">Контакты</h3>

        <v-text-field
          class="required"
          label="Мобильный телефон"
          v-model="form.cellularPhone"
          v-mask="'(###) ###-##-##'"
          :error-messages="errorMessages.cellularPhone"
          @input="$v.form.cellularPhone.$touch()"
          @blur="$v.form.cellularPhone.$touch()"
        />

        <v-text-field
          label="Домашний телефон"
          v-model="form.homePhoneNumber"
          v-mask="'##############################'"
          :error-messages="errorMessages.homePhoneNumber"
          @input="$v.form.homePhoneNumber.$touch()"
          @blur="$v.form.homePhoneNumber.$touch()"
        />

        <v-text-field
          class="required"
          label="Электронная почта"
          v-model="form.email"
          :error-messages="errorMessages.email"
          @input="$v.form.email.$touch()"
          @blur="$v.form.email.$touch()"
        />
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import { catalogApi, departmentApi } from '@/api'
import { mapGetters } from 'vuex'
import _isEqual from 'lodash/isEqual'
import { required, requiredIf, email, maxLength } from 'vuelidate/lib/validators'
import {
  cyrillic,
  dateFormat,
  snilsFormat,
  stopSymbols,
  checkSnilsSum,
  checkMinAge,
  setValidateMessages
} from '@/scripts/validation'
import { PersonalData } from '@/models'

export default {
  name: 'ListenerCardPersonalData',

  props: {
    data: Object,
    tabEditability: Object
  },

  async created() {
    this.markDefaultRequiredFields()
    await this.getDictionaries()
    await this.getCycles()
    this.$emit('dataLoaded')

    this.setCycleNumFromLocalStorage()
    this.setValidStatus()
  },

  data: () => ({
    nationalities: [],
    cycles: [],
    genders: [],
    identityCards: [],
    addressStates: [],
    cities: [],
    homeCities: [],
    citiesLoading: false,
    homeCitiesLoading: false,

    form: new PersonalData()
  }),

  validations() {
    return {
      form: {
        nationality: { required },
        cycleId: { required },
        lastName: { required, cyrillic, maxLength: maxLength(255) },
        firstName: { required, cyrillic, maxLength: maxLength(255) },
        middleName: { cyrillic, maxLength: maxLength(255) },
        lastNameGen: { required, cyrillic, maxLength: maxLength(255) },
        firstNameGen: { required, cyrillic, maxLength: maxLength(255) },
        middleNameGen: { cyrillic, maxLength: maxLength(255) },
        gender: { required },
        snils: { required: requiredIf(() => this.form.isRussian), snilsFormat, checkSnilsSum },
        birthDate: { required, dateFormat, checkMinAge },

        identityCardType: { required },
        migrationServiceIdentifier: {
          required: requiredIf(() => this.form.isMigrationIdentityCard),
          maxLength: maxLength(20)
        },
        identityCardSeries: {
          required: requiredIf(() => this.form.isPassportIdentityCard),
          maxLength: maxLength(this.form.isPassportIdentityCard ? 4 : 20)
        },
        identityCardNumber: { required, maxLength: maxLength(this.form.isPassportIdentityCard ? 6 : 30) },
        identityCardIssueDate: { required, dateFormat },
        identityCardIssueBy: { maxLength: maxLength(200) },

        countryRegion: { required },
        state: { required: requiredIf(() => this.form.isRusCountryRegion) },
        address: { required, maxLength: maxLength(250) },

        homeCountryRegion: { required },
        homeState: { required: requiredIf(() => this.form.isRusHomeCountryRegion) },
        homeAddress: { maxLength: maxLength(250) },

        cellularPhone: { required, maxLength: maxLength(30) },
        homePhoneNumber: { maxLength: maxLength(30) },
        email: { required, email, stopSymbols, maxLength: maxLength(80) }
      }
    }
  },

  computed: {
    ...mapGetters('user', ['isSuperAdmin']),
    ...mapGetters('department', ['departmentId']),

    errorMessages() {
      const allErrors = {}

      Object.keys(this.$v.form.$params).forEach(key => {
        allErrors[key] = setValidateMessages(this.$v.form[key])
      })

      return allErrors
    },

    showSaveBtn() {
      if (!this.$v.form.nationality.$invalid && !this.$v.form.cycleId.$invalid) {
        return this.form.isRussian
          ? !this.$v.form.snils.$invalid
          : !this.$v.form.identityCardType.$invalid && !this.$v.form.identityCardNumber.$invalid
      }

      return false
    },

    isCardDisabled() {
      return this.tabEditability?.mainTab || false
    }
  },

  methods: {
    async getDictionaries() {
      try {
        const [nationalities, genders, identityCards, addressStates] = await Promise.all([
          catalogApi.getAdressCountryRegions(),
          catalogApi.getGenders(),
          catalogApi.getIdentityCards(),
          catalogApi.getAddressStates(),
        ])

        this.nationalities = nationalities
        this.genders = genders
        this.identityCards = identityCards
        this.addressStates = addressStates
      } catch (e) {
        console.log(e.status)
      }
    },

    async getCycles() {
      try {
        const cyclesList = this.isSuperAdmin
          ? await departmentApi.getCyclesAllTimeByDepartmentId(this.departmentId)
          : await departmentApi.getCyclesByDepartmentId(this.departmentId)

        this.cycles = cyclesList.map(cycle => {
          const cycleNum = this.isSuperAdmin
            ? `${cycle.cycleNum} (${cycle.academyYearId})`
            : cycle.cycleNum

          const cycleNumWithMessage = cycle.planDist === false
            ? `${cycleNum} *необходимо распределить часы в плановом расписании*`
            : cycleNum

          return {
            ...cycle,
            disabled: cycle.planDist === false,
            cycleNumWithMessage
          }
        })
      } catch (e) {
        console.log(e.status)
      }
    },

    async getCities(state, type) {
      try {
        switch(type) {
          case 'state':
            this.citiesLoading = true
            this.cities = await catalogApi.getCities(state.id)
            break
          case 'homeState':
            this.homeCitiesLoading = true
            this.homeCities = await catalogApi.getCities(state.id)
        }
      } catch (e) {
        console.log(e.status)
      } finally {
        this.citiesLoading = false
        this.homeCitiesLoading = false
      }
    },

    setCycleNumFromLocalStorage() {
      if (['createListener', 'addListener'].includes(this.$route.name)) {
        const filters = JSON.parse(localStorage.getItem('listenersFilters'))

        if (filters && filters?.cycleNum) {
          const cycleFromList = this.cycles.find(el => el.cycleNum === filters.cycleNum) || null
          this.form.cycleId = cycleFromList.cycleId
        }
      }
    },

    markDefaultRequiredFields() {
      this.$v.form.nationality.$touch()
      this.$v.form.cycleId.$touch()
    },

    markRequiredFields() {
      if (this.form.isRussian) {
        this.$v.form.snils.$touch()
      } else {
        this.$v.form.identityCardType.$touch()
        this.$v.form.identityCardNumber.$touch()
      }
    },

    customMask(len) {
      const mask = Array(len).fill('F').join('')

      return {
        mask,
        tokens: {
          'F': { pattern: /^[ а-яА-ЯёЁA-Za-z0-9.-]*$/ }
        },
      }
    },

    setValidStatus() {
      this.$emit('setValidStatus', !this.$v.$invalid)
      this.$emit('showSaveBtn', this.showSaveBtn)
    }
  },

  watch: {
    data: {
      immediate: true,
      handler(val) {
        if (val) {
          if (!_isEqual(this.form, val)) {
            this.form = PersonalData.buildFromAPI(val)
            this.$v.form.snils.$touch()
            this.$v.form.birthDate.$touch()

            this.form.state && this.getCities(this.form.state, 'state')
            this.form.homeState && this.getCities(this.form.homeState, 'homeState')
          }
        } else {
          this.form = new PersonalData()
        }
      },
    },

    form: {
      deep: true,
      handler(data) {
        this.setValidStatus()
        this.$emit('updatePersonalDataCard', data)
      }
    }
  }
}
</script>

<style scoped>
.copy-checkbox {
  margin: 19px 0 21px -4px;
  padding-top: 0;
}

.home-address-title {
  margin-top: 62px;
  margin-bottom: 16px;
}

.contacts-title {
  margin-top: 40px;
  margin-bottom: 16px;
}
</style>