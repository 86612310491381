<template>
  <div>
    <div class="text-center" v-if="!dataLoaded || !allCardsDataLoaded">
      <v-progress-circular class="mt-6" :size="50" color="primary" indeterminate />
    </div>

    <div class="mx-4" v-if="dataLoaded">
      <div v-show="allCardsDataLoaded">
        <PageTitle :title="title" />

        <div class="d-flex justify-space-between align-end">
          <v-tabs
            v-model="tab"
            background-color="transparent"
            color="primary"
            show-arrows
            class="base-tabs custom-tabs-arrows"
          >
            <!-- если не использовать v-if на табах, то почему-то не появляется полоска под активным табом -->
            <v-tab v-for="(tab, i) in tabsComputed" :key="i" class="tab" v-if="allCardsDataLoaded">
              <span :class="{ 'tabError--text': !tab.isValid }">{{ tab.text }}</span>
            </v-tab>
          </v-tabs>

          <v-btn
            v-show="isSaveBtn"
            :disabled="!showSaveBtn"
            :loading="saveBtnLoading"
            small
            depressed
            color="success"
            class="white--text save-btn px-4 ml-4"
            @click="saveListenerCard"
          >
            <v-icon left size="20">mdi-content-save-outline</v-icon>Сохранить
          </v-btn>
        </div>

        <v-card class="mt-4 pa-5 base-card" ref="listenerCard">
          <PersonalDataCard
            v-show="tab === 0"
            :data="listenerCard.personalData"
            :tabEditability="tabEditability"
            @dataLoaded="cardsDataLoaded.personalData = true"
            @updatePersonalDataCard="updatePersonalDataCard"
            @setValidStatus="tabs[0].isValid = $event"
            @showSaveBtn="showSaveBtn = $event"
          />

          <EducationCard
            v-show="tab === 1"
            :data="listenerCard.education"
            :tabEditability="tabEditability"
            @dataLoaded="cardsDataLoaded.education = true"
            @setValidStatus="tabs[1].isValid = $event"
            @updateEducationCard="updateEducationCard"
          />

          <WorkCard
            v-show="tab === 2"
            :data="listenerCard.work"
            :tabEditability="tabEditability"
            @dataLoaded="cardsDataLoaded.work = true"
            @setValidStatus="tabs[2].isValid = $event"
            @updateWorkCard="updateWorkCard"
          />

          <TrainCard
            v-show="tab === 3"
            :data="listenerCard.train"
            :tabEditability="tabEditability"
            :cycleId="cycleId"
            :cardDocs="cardDocs"
            @dataLoaded="cardsDataLoaded.train = true"
            @setValidStatus="tabs[3].isValid = $event"
            @updateTrainCard="updateTrainCard"
            @changeTrainTabEnvironment="changeTrainTabEnvironment"
          />

          <AddEducationCard
            v-show="tab === 4"
            :data="listenerCard.addEducation"
            :tabEditability="tabEditability"
            @dataLoaded="cardsDataLoaded.addEducation = true"
            @setValidStatus="tabs[4].isValid = $event"
            @updateAddEducationCard="updateAddEducationCard"
          />
        </v-card>
      </div>
    </div>
  </div>
</template>

<script>
import { cycleApi, fileDpoCardApi } from '@/api'
import { mapGetters } from 'vuex'
import _cloneDeep from 'lodash/cloneDeep'
import _isEqual from 'lodash/isEqual'
import { ListenerCard } from '@/models'

import PageTitle from '@/components/ui/Title'
import PersonalDataCard from '@/components/listeners/listener/card/PersonalData'
import EducationCard from '@/components/listeners/listener/card/Education'
import WorkCard from '@/components/listeners/listener/card/Work'
import TrainCard from '@/components/listeners/listener/card/Train'
import AddEducationCard from '@/components/listeners/listener/card/AddEducation'

export default {
  name: 'ListenerCard',

  metaInfo() {
    return {
      title: this.title
    }
  },

  props: {
    addItem: Object
  },

  components: {
    PageTitle,
    PersonalDataCard,
    EducationCard,
    WorkCard,
    TrainCard,
    AddEducationCard
  },

  async created() {
    await this.$store.dispatch('user/getCurrent')
    await this.$store.dispatch('department/checkDepartmentInLocalStorage')
    await this.getData()
    this.dataLoaded = true
    this.setCardHeight()
  },

  mounted() {
    this.hideHtmlOverflow()
    window.addEventListener("resize", this.setCardHeight)
  },

  destroyed() {
    this.showHtmlOverflow()
    window.removeEventListener("resize", this.setCardHeight)
  },

  data: () => ({
    dataLoaded: false,
    cardsDataLoaded: {
      personalData: false,
      education: false,
      work: false,
      train: false,
      addEducation: false
    },

    tab: 0,
    tabs: [
      { text: 'Основная', isValid: false },
      { text: 'Образование', isValid: false },
      { text: 'Место работы', isValid: false },
      { text: 'Текущее повышение квалификации', isValid: false },
      { text: 'Доп. образование', isValid: false }
    ],
    tabEditability: null,
    showSaveBtn: false,
    saveBtnLoading: false,
    cardDocs: [],

    listenerCard: new ListenerCard()
  }),

  computed: {
    ...mapGetters('system', ['techMessageHeight']),

    cycleId() {
      return this.listenerCard.personalData.cycleId || null
    },

    title() {
      switch (this.$route.name) {
        case 'addListener': return 'Добавление слушателя'
        case 'createListener': return 'Создание слушателя'
        case 'editListener': return 'Редактирование слушателя'
      }
    },

    isFifthTab() {
      return this.listenerCard.train.environment?.id === 4
    },

    tabsComputed() {
      return this.isFifthTab ? this.tabs : this.tabs.slice(0, -1)
    },

    allCardsDataLoaded() {
      return Object.keys(this.cardsDataLoaded).every(key => this.cardsDataLoaded[key])
    },

    isSaveBtn() {
      if (!this.tabEditability) return true

      const allTabsKeys = ['mainTab', 'eduTab', 'jobTab', 'trainTab', 'natPrjTab']
      const tabsKeys = this.isFifthTab ? allTabsKeys : allTabsKeys.slice(0, -1)

      return !tabsKeys.every(key => this.tabEditability[key])
    }
  },

  methods: {
    setCardHeight() {
      this.$nextTick(() => {
        const otherElements = 260 + this.techMessageHeight

        if (this.$refs?.listenerCard) {
          this.$refs.listenerCard.$el.style.maxHeight = window.innerHeight - otherElements + 'px'
        }
      })
    },

    updatePersonalDataCard(data) {
      if (!_isEqual(this.listenerCard.personalData, data)) {
        this.listenerCard.personalData = _cloneDeep(data)
      }
    },

    updateEducationCard(data) {
      if (!_isEqual(this.listenerCard.education, data)) {
        this.listenerCard.education = _cloneDeep(data)
      }
    },

    updateWorkCard(data) {
      if (!_isEqual(this.listenerCard.work, data)) {
        this.listenerCard.work = _cloneDeep(data)
      }
    },

    updateTrainCard(data) {
      if (!_isEqual(this.listenerCard.train, data)) {
        this.listenerCard.train = _cloneDeep(data)
      }
    },

    updateAddEducationCard(data) {
      if (!_isEqual(this.listenerCard.addEducation, data)) {
        this.listenerCard.addEducation = _cloneDeep(data)
      }
    },

    changeTrainTabEnvironment() {
      this.listenerCard.clearAddEducation()
    },

    async getData() {
      switch (this.$route.name) {
        case 'addListener':
          this.generateListenerCardByParams()
          break
        case 'editListener':
          await this.getListenerCard()
          await this.getListenersTabEditability()
      }
    },

    async getListenerCard() {
      const listenerId = +this.$route.params?.id

      const data = await fileDpoCardApi.getListenerCard(listenerId)
      data && this.listenerCard.buildFromAPI(data)

      await this.getCardDocs(listenerId)
    },

    async getCardDocs(listenerId) {
      this.cardDocs = await fileDpoCardApi.getCardDocs(listenerId)
    },

    async getListenersTabEditability() {
      const cycleId = this.listenerCard.personalData.cycleId

      if (cycleId) {
        this.tabEditability = await cycleApi.getListenersTabEditability(cycleId)
      }
    },

    generateListenerCardByParams() {
      if (this.addItem) {
        this.listenerCard.buildFromAPI(this.addItem)
      } else {
        this.$router.push('/listeners')
      }
    },

    async saveListenerCard() {
      try {
        this.saveBtnLoading = true

        let response
        const body = this.listenerCard.getForAPI()

        response = body.id
          ? await fileDpoCardApi.updateListenerCard(body)
          : await fileDpoCardApi.saveListenerCard(body)

        if (response?.data?.message) {
          this.$toastr('warning', '', response.data.message)
        }

        await this.$router.push('/listeners')
      } catch (e) {
        this.showErrorMessage(e, 'Ошибка сохранения карточки слушателя')
      } finally {
        this.saveBtnLoading = false
      }
    },

    hideHtmlOverflow() {
      document.querySelector('html').style.setProperty('overflow-y', 'hidden', 'important')
    },

    showHtmlOverflow() {
      document.querySelector('html').style.overflowY = 'visible'
    }
  }
}
</script>

<style scoped>
.base-card {
  overflow-y: auto;
  border-bottom: 20px solid #fff;
}
</style>