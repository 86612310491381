<template>
  <v-card
    class="tab-content"
    elevation="0"
    :disabled="isCardDisabled"
    v-disable-children="isCardDisabled"
  >
    <v-row class="mt-0">
      <v-col cols="6" class="py-0">
        <h3 class="mt-0">Образование</h3>

        <v-autocomplete
          class="required"
          v-model="form.eduLevel"
          hide-no-data
          :items="eduLevels"
          item-text="name"
          label="Уровень образования"
          placeholder="Выберите из списка"
          return-object
          :error-messages="errorMessages.eduLevel"
          @input="$v.form.eduLevel.$touch()"
          @blur="$v.form.eduLevel.$touch()"
        />

        <div class="d-flex">
          <v-autocomplete
            v-if="!form.eduSchoolCheck"
            class="required"
            v-model="form.eduSchool"
            hide-no-data
            :items="highSchools"
            item-text="shortName"
            label="Учебное заведение"
            placeholder="Выберите из списка"
            return-object
            :error-messages="errorMessages.eduSchool"
            @input="$v.form.eduSchool.$touch()"
            @blur="$v.form.eduSchool.$touch()"
          />

          <v-text-field
            v-else
            class="required"
            label="Введите учебное заведение"
            v-model="form.eduSchoolOutList"
            :error-messages="errorMessages.eduSchoolOutList"
            @input="$v.form.eduSchoolOutList.$touch()"
            @blur="$v.form.eduSchoolOutList.$touch()"
          />

          <v-checkbox
            v-model="form.eduSchoolCheck"
            class="ml-4 mt-6"
            label="Не найдено"
            hide-details
          />
        </div>

        <v-autocomplete
          class="required"
          v-model="form.eduYear"
          hide-no-data
          :items="eduYears"
          item-text="name"
          label="Год окончания"
          placeholder="Выберите из списка"
          return-object
          :error-messages="errorMessages.eduYear"
          @input="$v.form.eduYear.$touch()"
          @blur="$v.form.eduYear.$touch()"
        />

        <h3>Документ об образовании</h3>

        <v-autocomplete
          class="required"
          v-model="form.eduCardType"
          hide-no-data
          :items="diplomaTypes"
          item-text="description"
          label="Тип документа"
          placeholder="Выберите из списка"
          return-object
          :error-messages="errorMessages.eduCardType"
          @input="$v.form.eduCardType.$touch()"
          @blur="$v.form.eduCardType.$touch()"
        />

        <v-text-field
          :class="{'required': form.isDiploma}"
          label="Серия"
          v-model="form.eduCardSeries"
          :error-messages="errorMessages.eduCardSeries"
          @input="$v.form.eduCardSeries.$touch()"
          @blur="$v.form.eduCardSeries.$touch()"
        />

        <v-text-field
          class="required"
          label="Номер"
          v-model="form.eduCardNumber"
          hide-details
          :error-messages="errorMessages.eduCardNumber"
          @input="$v.form.eduCardNumber.$touch()"
          @blur="$v.form.eduCardNumber.$touch()"
        />

        <v-checkbox
          class="equal-places"
          label="Место выдачи совпадает с учебным заведением"
          hide-details
          @change="form.copyEduSchoolInfo($event)"
        />

        <div class="d-flex">
          <v-autocomplete
            v-if="!form.eduFirstSpecCenterCheck"
            v-model="form.eduFirstSpecCenter"
            hide-no-data
            :items="highSchools"
            item-text="shortName"
            label="Кем выдано"
            placeholder="Выберите из списка"
            return-object
          />

          <v-text-field
            v-if="form.eduFirstSpecCenterCheck"
            label="Введите учебное заведение"
            v-model="form.eduFirstSpecCenterOutList"
            :error-messages="errorMessages.eduFirstSpecCenterOutList"
            @input="$v.form.eduFirstSpecCenterOutList.$touch()"
            @blur="$v.form.eduFirstSpecCenterOutList.$touch()"
          />

          <v-checkbox
            v-model="form.eduFirstSpecCenterCheck"
            class="ml-4 mt-6"
            label="Не найдено"
            hide-details
          />
        </div>

        <v-text-field
          class="required"
          label="Дата выдачи документа"
          v-model="form.eduCardIssueDate"
          v-mask="'##.##.####'"
          :error-messages="errorMessages.eduCardIssueDate"
          @input="$v.form.eduCardIssueDate.$touch()"
          @blur="$v.form.eduCardIssueDate.$touch()"
        />

        <h3>Специальность по диплому</h3>

        <v-autocomplete
          class="required"
          v-model="form.eduSpecialtySpc"
          hide-no-data
          :items="specialitySpc"
          item-text="specialtyName"
          label="Специалитет"
          placeholder="Выберите из списка"
          return-object
          :error-messages="errorMessages.eduSpecialtySpc"
          @input="$v.form.eduSpecialtySpc.$touch()"
          @blur="$v.form.eduSpecialtySpc.$touch()"
        />

        <v-autocomplete
          v-model="form.eduSpecialtyInt"
          hide-no-data
          :items="specialityIntOrd"
          item-text="description"
          label="Интернатура"
          placeholder="Выберите из списка"
          return-object
        />

        <v-autocomplete
          v-model="form.eduSpecialtyOrd"
          hide-no-data
          :items="specialityIntOrd"
          item-text="description"
          label="Ординатура"
          placeholder="Выберите из списка"
          return-object
        />

        <v-autocomplete
          v-model="form.eduSpecialtyAsp"
          hide-no-data
          :items="specialityAsp"
          item-text="description"
          label="Аспирантура"
          placeholder="Выберите из списка"
          return-object
        />

        <v-autocomplete
          v-model="form.eduSpecialtyDpo"
          hide-no-data
          :items="specialityDpo"
          item-text="specialtyName"
          label="Дополнительное профессиональное образование"
          placeholder="Выберите из списка"
          return-object
        />
      </v-col>

      <v-col cols="6" class="py-0">
        <h3 class="mt-0">Научная квалификация</h3>

        <v-autocomplete
          class="required"
          v-model="form.eduAcademicDegree"
          hide-no-data
          :items="academicDegrees"
          item-text="name"
          label="Степень"
          placeholder="Выберите из списка"
          return-object
          :error-messages="errorMessages.eduAcademicDegree"
          @input="$v.form.eduAcademicDegree.$touch()"
          @blur="$v.form.eduAcademicDegree.$touch()"
        />

        <v-text-field
          label="Номер документа (степень)"
          v-model="form.eduAcademicDocNumber"
          :error-messages="errorMessages.eduAcademicDocNumber"
          @input="$v.form.eduAcademicDocNumber.$touch()"
          @blur="$v.form.eduAcademicDocNumber.$touch()"
        />

        <v-text-field
          label="Дата выдачи (степень)"
          v-model="form.eduAcademicDocDate"
          v-mask="'##.##.####'"
          :error-messages="errorMessages.eduAcademicDocDate"
          @input="$v.form.eduAcademicDocDate.$touch()"
          @blur="$v.form.eduAcademicDocDate.$touch()"
        />

        <v-autocomplete
          v-model="form.eduAcademicCategory"
          hide-no-data
          :items="academicCategories"
          item-text="name"
          label="Звание"
          placeholder="Выберите из списка"
          return-object
        />

        <v-autocomplete
          v-model="form.eduQualificationCategory"
          hide-no-data
          :items="categoryPersons"
          item-text="name"
          label="Квалификационная категория"
          placeholder="Выберите из списка"
          return-object
        />

        <h3>Индивидуальный номер мед. работника</h3>

        <v-text-field
          label="Номер мед. работника"
          v-model="form.indNumOfHealthWorker"
          :error-messages="errorMessages.indNumOfHealthWorker"
          @input="$v.form.indNumOfHealthWorker.$touch()"
          @blur="$v.form.indNumOfHealthWorker.$touch()"
        />

        <v-text-field
          label="Дата включения в реестр"
          v-model="form.dateInclude2Register"
          v-mask="'##.##.####'"
          :error-messages="errorMessages.dateInclude2Register"
          @input="$v.form.dateInclude2Register.$touch()"
          @blur="$v.form.dateInclude2Register.$touch()"
        />

        <v-radio-group
          v-model="form.isValidAccred"
          class="pt-0 mt-0 mb-4"
          row
          hide-details
        >
          <span class="label-span accent--text required-span">Свидетельство об аккредитации</span>
          <v-radio class="accent--text" label="Да" :value="true" />
          <v-radio class="accent--text" label="Нет" :value="false" />
        </v-radio-group>

        <div v-if="form.isValidAccred">
          <v-text-field
            v-model="form.validAccredGosNum"
            class="required"
            label="Государственный номер свидетельства"
            :error-messages="errorMessages.validAccredGosNum"
            @input="$v.form.validAccredGosNum.$touch()"
            @blur="$v.form.validAccredGosNum.$touch()"
          />

          <v-text-field
            v-model="form.validAccredRegNum"
            class="required"
            label="Регистрационный номер свидетельства"
            :error-messages="errorMessages.validAccredRegNum"
            @input="$v.form.validAccredRegNum.$touch()"
            @blur="$v.form.validAccredRegNum.$touch()"
          />

          <v-autocomplete
            class="required"
            v-model="form.specialityTable"
            hide-no-data
            :items="specialityDpo"
            item-text="specialtyName"
            label="Специальность"
            placeholder="Выберите из списка"
            return-object
            :error-messages="errorMessages.specialityTable"
            @input="$v.form.specialityTable.$touch()"
            @blur="$v.form.specialityTable.$touch()"
          />
        </div>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import { catalogApi } from '@/api'
import _isEqual from 'lodash/isEqual'
import { required, requiredIf, maxLength } from 'vuelidate/lib/validators'
import { dateFormat, setValidateMessages } from '@/scripts/validation'
import { Education } from '@/models'

export default {
  name: 'ListenerCardEducation',

  props: {
    data: Object,
    tabEditability: Object
  },

  async created() {
    await this.getDictionaries()

    this.setValidStatus()
  },

  data: () => ({
    eduLevels: [],
    highSchools: [],
    eduYears: [],
    diplomaTypes: [],
    specialitySpc: [],
    specialityIntOrd: [],
    specialityAsp: [],
    specialityDpo: [],
    academicDegrees: [],
    academicCategories: [],
    categoryPersons: [],

    form: new Education()
  }),

  validations() {
    return {
      form: {
        eduLevel: { required },
        eduSchool: { required: requiredIf(() => !this.form.eduSchoolCheck) },
        eduSchoolOutList: { required: requiredIf(() => this.form.eduSchoolCheck), maxLength: maxLength(255) },
        eduYear: { required },
        eduCardType: { required },
        eduCardSeries: { required: requiredIf(() => this.form.isDiploma), maxLength: maxLength(50) },
        eduCardNumber: { required, maxLength: maxLength(50) },
        eduFirstSpecCenterOutList: { maxLength: maxLength(255) },
        eduCardIssueDate: { required, dateFormat },
        eduSpecialtySpc: { required },
        eduAcademicDegree: { required },
        eduAcademicDocNumber: { maxLength: maxLength(50) },
        eduAcademicDocDate: { dateFormat },
        indNumOfHealthWorker: { maxLength: maxLength(18) },
        dateInclude2Register: { dateFormat },

        validAccredGosNum: { required: requiredIf(() => this.form.isValidAccred), maxLength: maxLength(30) },
        validAccredRegNum: { required: requiredIf(() => this.form.isValidAccred), maxLength: maxLength(30) },
        specialityTable: { required: requiredIf(() => this.form.isValidAccred) }
      }
    }
  },

  computed: {
    errorMessages() {
      const allErrors = {}

      Object.keys(this.$v.form.$params).forEach(key => {
        allErrors[key] = setValidateMessages(this.$v.form[key])
      })

      return allErrors
    },

    isCardDisabled() {
      return this.tabEditability?.eduTab || false
    }
  },

  methods: {
    async getDictionaries() {
      try {
        const [list, list2, list3, list4, list5, list6, list7, list8, list9, list10, list11] = await Promise.all([
          catalogApi.getEduLevels(),
          catalogApi.getHighSchools(),
          catalogApi.getEduYears(),
          catalogApi.getDiplomaTypes(),

          catalogApi.getSpecialityTableByEduLevel(1),
          catalogApi.getSpeciality(2),
          catalogApi.getSpeciality(3),
          catalogApi.getSpecialityTable(),

          catalogApi.getAcademicDegrees(),
          catalogApi.getAcademicCategories(),
          catalogApi.getCategoryPersons()
        ])

        this.eduLevels = list
        this.highSchools = list2
        this.eduYears = list3
        this.diplomaTypes = list4
        this.specialitySpc = list5
        this.specialityIntOrd = list6
        this.specialityAsp = list7
        this.specialityDpo = list8
        this.academicDegrees = list9
        this.academicCategories = list10
        this.categoryPersons = list11

        this.$emit('dataLoaded')
      } catch (e) {
        console.log(e.status)
      }
    },

    setValidStatus() {
      this.$emit('setValidStatus', !this.$v.$invalid)
    }
  },

  watch: {
    data: {
      immediate: true,
      handler(val) {
        if (val) {
          if (!_isEqual(this.form, val)) {
            this.form = Education.buildFromAPI(val)
          }
        } else {
          this.form = new Education()
        }
      },
    },

    form: {
      deep: true,
      handler(data) {
        this.setValidStatus()
        this.$emit('updateEducationCard', data)
      }
    }
  }
}
</script>

<style scoped>
.equal-places {
  margin: 12px 0 16px -4px;
  padding-top: 0;
}

.label-span {
  width: 100%;
  margin-bottom: 8px;
  font-size: 14px;
  color: var(--accent-color) !important;
}
</style>