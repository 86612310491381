<template>
  <v-card
    class="tab-content"
    elevation="0"
    :disabled="isCardDisabled"
    v-disable-children="isCardDisabled"
  >
    <v-row class="mt-0">
      <v-col cols="6" class="py-0">
        <v-autocomplete
          class="required"
          v-model="form.environment"
          :disabled="!eduCycleTypeId || isCardDisabled"
          hide-no-data
          :items="payWayCyclesComputed"
          item-text="name"
          :label="payWayCyclesLabel"
          placeholder="Выберите из списка"
          return-object
          :error-messages="errorMessages.environment"
          @input="$v.form.environment.$touch()"
          @blur="$v.form.environment.$touch()"
          @change="$emit('changeTrainTabEnvironment')"
        />

        <v-text-field
          v-model="form.contractNum"
          label="Договор №"
          placeholder="№ договора в формате: № путевки/№цикла/год"
          autocomplete="new-password"
          :error-messages="errorMessages.contractNum"
          @input="$v.form.contractNum.$touch()"
          @blur="$v.form.contractNum.$touch()"
        />

        <v-text-field
          v-model="form.contractDate"
          label="Дата выдачи"
          v-mask="'##.##.####'"
          :error-messages="errorMessages.contractDate"
          @input="$v.form.contractDate.$touch()"
          @blur="$v.form.contractDate.$touch()"
        />

        <v-text-field
          v-model="form.enrollmentDate"
          class="required"
          label="Дата зачисления"
          v-mask="'##.##.####'"
          :error-messages="errorMessages.enrollmentDate"
          @input="$v.form.enrollmentDate.$touch()"
          @blur="$v.form.enrollmentDate.$touch()"
        />

        <v-radio-group
          v-model="form.enrollmentState"
          class="pt-0 mt-0 mb-4"
          row
          hide-details
        >
          <span class="label-span">Статус зачисления</span>
          <v-radio label="Да" :value="true" />
          <v-radio label="Нет" :value="false" />
        </v-radio-group>

        <v-radio-group
          v-model="form.isHotel"
          class="pt-0 mt-0 mb-2"
          row
        >
          <span class="label-span">Проживает во время обучения в общежитии</span>
          <v-radio label="Да" :value="true" />
          <v-radio label="Нет" :value="false" />
        </v-radio-group>

        <v-text-field
          v-model="form.listenerNumber"
          class="pt-0"
          label="Номер слушателя на программе ПП (ПК)"
          :error-messages="errorMessages.listenerNumber"
          @input="$v.form.listenerNumber.$touch()"
          @blur="$v.form.listenerNumber.$touch()"
        />

        <v-text-field
          v-model="form.nmo"
          class="pb-2"
          label="№ НМО"
          :error-messages="errorMessages.nmo"
          @input="$v.form.nmo.$touch()"
          @blur="$v.form.nmo.$touch()"
        />
      </v-col>

      <v-col cols="6" class="py-0">
        <v-radio-group
          v-model="form.retraining"
          class="pt-0 mt-0 mb-4"
          row
          hide-details
        >
          <span class="label-span required-span">Профессиональная переподготовка</span>
          <v-radio label="Да" :value="true" />
          <v-radio label="Нет" :value="false" />
        </v-radio-group>

        <div class="card-docs" v-if="cardDocs.length">
          <h3 class="accent--text mb-4 mt-6">Удостоверение о повышении квалификации</h3>

          <div v-for="doc in cardDocs" :key="doc.id">
            <p>
              <b>Тип документа: </b>
              <span>{{ doc.qualificationCardType }}</span>
              <span v-if="doc.isDublicate"> (дубликат)</span>
            </p>

            <p>
              <b>Серия: </b><span>{{ doc.qualificationCardSeries }}</span><br>
              <b>Номер: </b><span>{{ doc.qualificationCardNum }}</span><br>
              <b>Гос.номер: </b><span>{{ doc.qualificationCardGosNum }}</span><br>
              <b>Дата: </b><span>{{ doc.docIssueDate }}</span>
            </p>
          </div>
        </div>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import { catalogApi, cycleApi } from '@/api'
import _isEqual from 'lodash/isEqual'
import { dateFormat, setValidateMessages } from '@/scripts/validation'
import { maxLength, required } from 'vuelidate/lib/validators'
import { Train } from '@/models'

export default {
  name: 'ListenerCardTrain',

  props: {
    data: Object,
    tabEditability: Object,
    cycleId: Number,
    cardDocs: Array
  },

  async created() {
    await this.getDictionaries()

    this.setValidStatus()
  },

  data: () => ({
    eduCycleTypeId: null,
    payWayCycles: [],

    form: new Train()
  }),

  validations() {
    return {
      form: {
        environment: { required },
        contractNum: { maxLength: maxLength(80) },
        contractDate: { dateFormat },
        enrollmentDate: { required, dateFormat },
        listenerNumber: { maxLength: maxLength(6) },
        nmo: { maxLength: maxLength(20) }
      }
    }
  },

  computed: {
    payWayCyclesLabel() {
      return this.eduCycleTypeId ? 'Источник финансирования' : 'Источник финансирования (сначала выберите номер цикла)'
    },

    payWayCyclesComputed() {
      if ([3, 4, 5, 6, 7, 8, 10].includes(this.eduCycleTypeId)) {
        return this.payWayCycles.filter(el => [3, 4, 5, 6].includes(el.id))
      }

      return this.payWayCycles
    },

    errorMessages() {
      const allErrors = {}

      Object.keys(this.$v.form.$params).forEach(key => {
        allErrors[key] = setValidateMessages(this.$v.form[key])
      })

      return allErrors
    },

    isCardDisabled() {
      return this.tabEditability?.trainTab || false
    }
  },

  methods: {
    async getDictionaries() {
      try {
        const [list] = await Promise.all([
          catalogApi.getPayWayCycles(),
        ])

        this.payWayCycles = list

        this.$emit('dataLoaded')
      } catch (e) {
        console.log(e.status)
      }
    },

    async getCycleInfo(cycleId) {
      try {
        const data = await cycleApi.getCycleInfo(cycleId)
        this.eduCycleTypeId = data?.eduCycleType?.id || null
      } catch (e) {
        console.log(e.status)
      }
    },

    setValidStatus() {
      this.$emit('setValidStatus', !this.$v.$invalid)
    }
  },

  watch: {
    data: {
      immediate: true,
      handler(val) {
        if (val) {
          if (!_isEqual(this.form, val)) {
            this.form = Train.buildFromAPI(val)
          }
        } else {
          this.form = new Train()
        }
      },
    },

    form: {
      deep: true,
      handler(data) {
        this.setValidStatus()
        this.$emit('updateTrainCard', data)
      }
    },

    cycleId: {
      immediate: true,
      handler(val, oldVal) {
        val && this.getCycleInfo(val)

        if (val && oldVal) {
          this.form.environment = null
        }
      },
    }
  }
}
</script>

<style scoped>
.card-docs p {
  margin-bottom: 4px;
  font-size: 14px;
  color: var(--accent-color)
}

.card-docs p:first-child {
  margin-bottom: 12px;
}

.label-span {
  width: 100%;
  margin-bottom: 8px;
  font-size: 14px;
  color: var(--accent-color) !important;
}

 >>> .theme--light.v-input--is-disabled input[type=text] {
   color: var(--accent-color) !important;
}
</style>