<template>
  <v-card
    class="tab-content"
    elevation="0"
    :disabled="isCardDisabled"
    v-disable-children="isCardDisabled"
  >
    <v-row class="mt-0">
      <v-col cols="6" class="py-0">
        <h3 class="mt-0">Место работы</h3>

        <v-checkbox
          v-model="form.workStatusCheck"
          class="work-status"
          label="Занят / Не занят"
          hide-details
        />

        <v-autocomplete
          :class="{'required': form.workStatusCheck}"
          v-model="form.workCountry"
          hide-no-data
          :items="nationalities"
          item-text="name"
          label="Страна"
          placeholder="Выберите из списка"
          return-object
          :error-messages="errorMessages.workCountry"
          @input="$v.form.workCountry.$touch()"
          @blur="$v.form.workCountry.$touch()"
        />

        <v-autocomplete
          v-if="form.isRusWorkCountry"
          :class="{'required': form.workStatusCheck}"
          v-model="form.workState"
          hide-no-data
          :items="states"
          item-text="name"
          label="Регион"
          placeholder="Выберите из списка"
          return-object
          :error-messages="errorMessages.workState"
          @input="$v.form.workState.$touch(); getCities($event)"
          @blur="$v.form.workState.$touch()"
        />

        <div class="d-flex" v-if="form.workState">
          <v-autocomplete
            v-if="!form.workTownCheck"
            :loading="citiesLoading"
            v-model="form.workTown"
            autocomplete="new-password"
            hide-no-data
            :items="cities"
            item-text="name"
            label="Город"
            placeholder="Выберите из списка"
            return-object
          />

          <v-text-field
            v-if="form.workTownCheck"
            v-model="form.workTownOutList"
            label="Введите название города"
            autocomplete="new-password"
            :error-messages="errorMessages.workTownOutList"
            @input="$v.form.workTownOutList.$touch()"
            @blur="$v.form.workTownOutList.$touch()"
          />

          <v-checkbox
            v-model="form.workTownCheck"
            class="ml-3"
            label="Не найдено"
            hide-details
          />
        </div>

        <v-textarea
          v-model="form.workPlaceOutList"
          :class="{'required': form.workStatusCheck}"
          label="Место работы"
          no-resize
          auto-grow
          rows="1"
          :error-messages="errorMessages.workPlaceOutList"
          @input="$v.form.workPlaceOutList.$touch()"
          @blur="$v.form.workPlaceOutList.$touch()"
        />

        <v-textarea
          v-model="form.workPlaceAddress"
          label="Адрес места работы"
          no-resize
          auto-grow
          rows="1"
          :error-messages="errorMessages.workPlaceAddress"
          @input="$v.form.workPlaceAddress.$touch()"
          @blur="$v.form.workPlaceAddress.$touch()"
        />

        <v-autocomplete
          :class="{'required': form.workStatusCheck}"
          v-model="form.workEnvironment"
          hide-no-data
          :items="payWays"
          item-text="name"
          label="Ведомственная принадлежность места работы"
          placeholder="Выберите из списка"
          return-object
          :error-messages="errorMessages.workEnvironment"
          @input="$v.form.workEnvironment.$touch()"
          @blur="$v.form.workEnvironment.$touch()"
        />

        <v-radio-group
          v-model="form.workIsSZGMUEmployee"
          class="pt-0 mt-0 mb-4"
          row
          hide-details
        >
          <span class="label-span">Работник СЗГМУ</span>
          <v-radio label="Да" :value="true" />
          <v-radio label="Нет" :value="false" />
        </v-radio-group>

        <v-radio-group
          v-model="form.workIsEmploymentService"
          class="pt-0 mt-0 mb-3"
          row
        >
          <span class="label-span">Незанятое лицо по направлению службы занятости</span>
          <v-radio label="Да" :value="true" />
          <v-radio label="Нет" :value="false" />
        </v-radio-group>

        <h3>Стаж работы</h3>

        <span class="group-label-span">Общий стаж работы</span>

        <v-row>
          <v-col>
            <v-text-field
              label="Количество лет"
              placeholder="0-75"
              v-model="form.workLengthTotalYear"
              v-mask="'##'"
              :error-messages="errorMessages.workLengthTotalYear"
              @input="$v.form.workLengthTotalYear.$touch()"
              @blur="$v.form.workLengthTotalYear.$touch()"
            />
          </v-col>

          <v-col>
            <v-text-field
              label="Количество месяцев"
              placeholder="0-11"
              v-model="form.workLengthTotalMonth"
              v-mask="'##'"
              :error-messages="errorMessages.workLengthTotalMonth"
              @input="$v.form.workLengthTotalMonth.$touch()"
              @blur="$v.form.workLengthTotalMonth.$touch()"
            />
          </v-col>
        </v-row>

        <span class="group-label-span accent--text">Стаж работы по специальности обучения</span>

        <v-row>
          <v-col>
            <v-text-field
              label="Количество лет"
              placeholder="0-75"
              v-model="form.workLengthYear"
              v-mask="'##'"
              :error-messages="errorMessages.workLengthYear"
              @input="$v.form.workLengthYear.$touch()"
              @blur="$v.form.workLengthYear.$touch()"
            />
          </v-col>

          <v-col>
            <v-text-field
              label="Количество месяцев"
              placeholder="0-11"
              v-model="form.workLengthMonth"
              v-mask="'##'"
              :error-messages="errorMessages.workLengthMonth"
              @input="$v.form.workLengthMonth.$touch()"
              @blur="$v.form.workLengthMonth.$touch()"
            />
          </v-col>
        </v-row>

      </v-col>

      <v-col cols="6" class="py-0">
        <h3 class="mt-0">Должность</h3>

        <v-autocomplete
          class="required"
          v-model="form.workCategory"
          hide-no-data
          :items="categoryPositions"
          item-text="categoryDescription"
          label="Категория должности"
          placeholder="Выберите из списка"
          return-object
          :error-messages="errorMessages.workCategory"
          @input="$v.form.workCategory.$touch()"
          @blur="$v.form.workCategory.$touch()"
        />

        <div class="d-flex">
          <v-autocomplete
            v-if="!form.workTitleCheck"
            v-model="form.workTitle"
            :loading="personTitlesLoading"
            class="required"
            label="Должность"
            placeholder="Начните вводить должность"
            no-data-text="Нет значений для выбора"
            item-text="title"
            hide-no-data
            hide-selected
            no-filter
            clearable
            :items="personTitles"
            return-object
            :error-messages="errorMessages.workTitle"
            @input="$v.form.workTitle.$touch()"
            @blur="$v.form.workTitle.$touch()"
            @update:search-input="findPersonTitles"
            @click:clear="clearWorkTitle"
          />

          <v-text-field
            v-if="form.workTitleCheck"
            class="required"
            label="Введите должность"
            v-model="form.workTitleOutList"
            :error-messages="errorMessages.workTitleOutList"
            @input="$v.form.workTitleOutList.$touch()"
            @blur="$v.form.workTitleOutList.$touch()"
          />

          <v-checkbox
            v-model="form.workTitleCheck"
            class="ml-4 mt-6"
            label="Не найдено"
            hide-details
          />
        </div>

        <v-text-field
          label="Должность на момент обучения по записи в трудовой книжке"
          v-model="form.workPosition"
          :error-messages="errorMessages.workPosition"
          @input="$v.form.workPosition.$touch()"
          @blur="$v.form.workPosition.$touch()"
        />

        <v-radio-group
          v-model="form.workIsManager"
          class="pt-0 mt-0 mb-4"
          row
          hide-details
        >
          <span class="label-span required-span">Руководитель структурного подразделения</span>
          <v-radio label="Да" :value="true" />
          <v-radio label="Нет" :value="false" />
        </v-radio-group>

        <v-radio-group
          v-model="form.workIsGoverEmpl"
          class="pt-0 mt-0 mb-4"
          row
          hide-details
        >
          <span class="label-span required-span">Государственный служащий</span>
          <v-radio label="Да" :value="true" />
          <v-radio label="Нет" :value="false" />
        </v-radio-group>

        <v-radio-group
          v-model="form.workMilitaryServDismiss"
          class="pt-0 mt-0 mb-4"
          row
          hide-details
        >
          <span class="label-span required-span">Уволен с военной службы</span>
          <v-radio label="Да" :value="true" />
          <v-radio label="Нет" :value="false" />
        </v-radio-group>

        <v-radio-group
          v-if="form.workMilitaryServDismiss"
          v-model="form.workMilitaryServDismissThisYear"
          class="pt-0 mt-0 mb-4"
          row
          hide-details
        >
          <span class="label-span required-span">В этом году</span>
          <v-radio label="Да" :value="true" />
          <v-radio label="Нет" :value="false" />
        </v-radio-group>

        <v-radio-group
          v-model="form.workIsTeacher"
          class="pt-0 mt-0 mb-3"
          row
          :hide-details="form.workIsTeacher"
        >
          <span class="label-span required-span">Является преподавателем</span>
          <v-radio label="Да" :value="true" />
          <v-radio label="Нет" :value="false" />
        </v-radio-group>

        <v-autocomplete
          v-if="form.workIsTeacher"
          class="required"
          v-model="form.workEduInstitution"
          hide-no-data
          :items="educationalInstitutions"
          item-text="name"
          label="Какого образовательного учреждения"
          placeholder="Выберите из списка"
          return-object
          :error-messages="errorMessages.workEduInstitution"
          @input="$v.form.workEduInstitution.$touch()"
          @blur="$v.form.workEduInstitution.$touch()"
        />

        <h3>Принадлежность врача к профессиональной группе</h3>

        <v-radio-group
          v-model="form.workIsRuralAreaDoctor"
          class="pt-0 mt-0 mb-4"
          row
          hide-details
        >
          <span class="label-span required-span">Врачи, провизоры сельской местности</span>
          <v-radio label="Да" :value="true" />
          <v-radio label="Нет" :value="false" />
        </v-radio-group>

        <v-radio-group
          v-model="form.workIsEmergencyDoctor"
          class="pt-0 mt-0 mb-4"
          row
          hide-details
        >
          <span class="label-span required-span">Врачи скорой помощи</span>
          <v-radio label="Да" :value="true" />
          <v-radio label="Нет" :value="false" />
        </v-radio-group>

        <v-radio-group
          v-model="form.workIsDistrictDoctor"
          class="pt-0 mt-0 mb-4"
          row
          hide-details
        >
          <span class="label-span required-span">Врачи участковые города</span>
          <v-radio label="Да" :value="true" />
          <v-radio label="Нет" :value="false" />
        </v-radio-group>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import { catalogApi } from '@/api'
import _isEqual from 'lodash/isEqual'
import _debounce from 'lodash/debounce'
import { setValidateMessages } from '@/scripts/validation'
import { maxLength, required, requiredIf, maxValue } from 'vuelidate/lib/validators'
import { Work } from '@/models'

export default {
  name: 'ListenerCardWork',

  props: {
    data: Object,
    tabEditability: Object
  },

  async created() {
    await this.getDictionaries()

    if (this.form.workTown) {
      this.cities = [this.form.workTown]
    }

    if (this.form.workTitle) {
      this.personTitles = [this.form.workTitle]
    }

    this.setValidStatus()
  },

  data: () => ({
    nationalities: [],
    states: [],
    cities: [],
    payWays: [],
    categoryPositions: [],
    personTitles: [],
    educationalInstitutions: [],
    citiesLoading: false,
    personTitlesLoading: false,

    form: new Work()
  }),

  validations() {
    return {
      form: {
        workCountry: { required: requiredIf(() => this.form.workStatusCheck) },
        workState: { required: requiredIf(() => this.form.isRusWorkCountry && this.form.workStatusCheck) },
        workTownOutList: { maxLength: maxLength(255) },
        workPlaceOutList: { required: requiredIf(() => this.form.workStatusCheck), maxLength: maxLength(255) },
        workPlaceAddress: { maxLength: maxLength(250) },
        workEnvironment: { required: requiredIf(() => this.form.workStatusCheck) },
        workLengthTotalYear: { maxValue: maxValue(75) },
        workLengthTotalMonth: { maxValue: maxValue(11) },
        workLengthYear: { maxValue: maxValue(75) },
        workLengthMonth: { maxValue: maxValue(11) },
        workCategory: { required },
        workTitle: { required: requiredIf(() => !this.form.workTitleCheck) },
        workTitleOutList: { required: requiredIf(() => this.form.workTitleCheck), maxLength: maxLength(100) },
        workPosition: { maxLength: maxLength(255) },
        workEduInstitution: { required: requiredIf(() => this.form.workIsTeacher) }
      }
    }
  },

  computed: {
    errorMessages() {
      const allErrors = {}

      Object.keys(this.$v.form.$params).forEach(key => {
        allErrors[key] = setValidateMessages(this.$v.form[key])
      })

      return allErrors
    },

    isCardDisabled() {
      return this.tabEditability?.jobTab || false
    }
  },

  methods: {
    async getDictionaries() {
      try {
        const [list, list1, list2, list3, list4] = await Promise.all([
          catalogApi.getAdressCountryRegions(),
          catalogApi.getAddressStates(),
          catalogApi.getPayWays(),
          catalogApi.getCategoryPositions(),
          catalogApi.getEducationalInstitutions()
        ])

        this.nationalities = list
        this.states = list1
        this.payWays = list2
        this.categoryPositions = list3
        this.educationalInstitutions = list4

        this.$emit('dataLoaded')
      } catch (e) {
        console.log(e.status)
      }
    },

    async getCities(state) {
      try {
        this.citiesLoading = true
        this.cities = await catalogApi.getCities(state.id)
      } catch (e) {
        console.log(e.status)
      } finally {
        this.citiesLoading = false
      }
    },

    findPersonTitles: _debounce(async function(val) {
      try {
        if (val?.length >= 3) {
          this.personTitlesLoading = true
          this.personTitles = await catalogApi.findPersonTitles(val)
        }
      } catch (e) {
        console.log(e.status)
      } finally {
        this.personTitlesLoading = false
      }
    }, 500),

    clearWorkTitle() {
      this.personTitles = []
      this.form.workTitle = null
    },

    setValidStatus() {
      this.$emit('setValidStatus', !this.$v.$invalid)
    }
  },

  watch: {
    data: {
      immediate: true,
      handler(val) {
        if (val) {
          if (!_isEqual(this.form, val)) {
            this.form = Work.buildFromAPI(val)
          }
        } else {
          this.form = new Work()
        }
      },
    },

    form: {
      deep: true,
      handler(data) {
        this.setValidStatus()
        this.$emit('updateWorkCard', data)
      }
    }
  }
}
</script>

<style scoped>
.work-status {
  margin: 12px 0 16px -2px;
  padding-top: 0;
}

.label-span, .group-label-span {
  width: 100%;
  margin-bottom: 8px;
  font-size: 14px;
  color: var(--accent-color) !important;
}

.group-label-span {
  display: block;
  font-weight: 500;
}
</style>