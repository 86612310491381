<template>
  <v-card
    class="tab-content"
    elevation="0"
    :disabled="isCardDisabled"
    v-disable-children="isCardDisabled"
  >
    <v-row class="mt-0">
      <v-col cols="6" class="py-0">
        <v-text-field
          label="Дата начала обучения"
          v-model="form.addEduBeginDate"
          v-mask="'##.##.####'"
          :error-messages="errorMessages.addEduBeginDate"
          @input="$v.form.addEduBeginDate.$touch()"
          @blur="$v.form.addEduBeginDate.$touch()"
        />

        <v-text-field
          label="Дата окончания обучения"
          v-model="form.addEduEndDate"
          v-mask="'##.##.####'"
          :error-messages="errorMessages.addEduEndDate"
          @input="$v.form.addEduEndDate.$touch()"
          @blur="$v.form.addEduEndDate.$touch()"
        />

        <v-autocomplete
          v-model="form.addEduSchool"
          hide-no-data
          :items="highSchools"
          item-text="shortName"
          label="Учреждение"
          placeholder="Выберите из списка"
          return-object
        />

        <v-autocomplete
          v-model="form.addEduForm"
          hide-no-data
          :items="eduFormCycles"
          item-text="name"
          label="Форма обучения"
          placeholder="Выберите из списка"
          return-object
        />

        <v-text-field
          label="Количество часов"
          v-model="form.addEduCountHours"
          v-mask="'###'"
          :error-messages="errorMessages.addEduCountHours"
          @input="$v.form.addEduCountHours.$touch()"
          @blur="$v.form.addEduCountHours.$touch()"
        />

        <v-textarea
          v-model="form.addEduPlace"
          label="Место прохождения"
          no-resize
          auto-grow
          rows="1"
          :error-messages="errorMessages.addEduPlace"
          @input="$v.form.addEduPlace.$touch()"
          @blur="$v.form.addEduPlace.$touch()"
        />
      </v-col>

      <v-col cols="6" class="py-0">
        <v-autocomplete
          v-model="form.addEduCardType"
          hide-no-data
          :items="diplomaTypes"
          item-text="description"
          label="Документ"
          placeholder="Выберите из списка"
          return-object
        />

        <v-text-field
          label="Серия"
          v-model="form.addEduCardSeries"
          :error-messages="errorMessages.addEduCardSeries"
          @input="$v.form.addEduCardSeries.$touch()"
          @blur="$v.form.addEduCardSeries.$touch()"
        />

        <v-text-field
          label="Номер"
          v-model="form.addEduCardNumber"
          :error-messages="errorMessages.addEduCardNumber"
          @input="$v.form.addEduCardNumber.$touch()"
          @blur="$v.form.addEduCardNumber.$touch()"
        />

        <v-text-field
          label="Дата выдачи"
          v-model="form.addEduCardIssueDate"
          v-mask="'##.##.####'"
          :error-messages="errorMessages.addEduCardIssueDate"
          @input="$v.form.addEduCardIssueDate.$touch()"
          @blur="$v.form.addEduCardIssueDate.$touch()"
        />

        <v-autocomplete
          v-model="form.addEduSpecialitySpc"
          hide-no-data
          :items="specialitySpc"
          item-text="specialtyName"
          label="Специалитет"
          placeholder="Выберите из списка"
          return-object
        />

        <v-autocomplete
          v-model="form.addEduSpecialityInt"
          hide-no-data
          :items="specialityIntOrd"
          item-text="description"
          label="Интернатура"
          placeholder="Выберите из списка"
          return-object
        />

        <v-autocomplete
          v-model="form.addEduSpecialityOrd"
          hide-no-data
          :items="specialityIntOrd"
          item-text="description"
          label="Ординатура"
          placeholder="Выберите из списка"
          return-object
        />

        <v-autocomplete
          v-model="form.addEduSpecialityIdAsp"
          hide-no-data
          :items="specialityAsp"
          item-text="description"
          label="Аспирантура"
          placeholder="Выберите из списка"
          return-object
        />

        <v-autocomplete
          v-model="form.addEduSpecialityDpo"
          hide-no-data
          :items="specialityDpo"
          item-text="specialtyName"
          label="Дополнительное профессиональное образование"
          placeholder="Выберите из списка"
          return-object
        />
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import { catalogApi } from '@/api'
import _isEqual from 'lodash/isEqual'
import { dateFormat, setValidateMessages, regexFrom0To999 } from '@/scripts/validation'
import { maxLength } from 'vuelidate/lib/validators'
import { AddEducation } from '@/models'

export default {
  name: 'ListenerCardAddEducation',

  props: {
    data: Object,
    tabEditability: Object
  },

  async created() {
    await this.getDictionaries()

    this.setValidStatus()
  },

  data: () => ({
    highSchools: [],
    eduFormCycles: [],
    diplomaTypes: [],
    specialitySpc: [],
    specialityIntOrd: [],
    specialityAsp: [],
    specialityDpo: [],

    form: new AddEducation()
  }),

  validations() {
    return {
      form: {
        addEduBeginDate: { dateFormat },
        addEduEndDate: { dateFormat },
        addEduCountHours: { regexFrom0To999 },
        addEduPlace: { maxLength: maxLength(250) },
        addEduCardSeries: { maxLength: maxLength(50) },
        addEduCardNumber: { maxLength: maxLength(50) },
        addEduCardIssueDate: { dateFormat }
      }
    }
  },

  computed: {
    errorMessages() {
      const allErrors = {}

      Object.keys(this.$v.form.$params).forEach(key => {
        allErrors[key] = setValidateMessages(this.$v.form[key])
      })

      return allErrors
    },

    isCardDisabled() {
      return this.tabEditability?.natPrjTab || false
    }
  },

  methods: {
    async getDictionaries() {
      try {
        const [list, list2, list3, list4, list5, list6, list7] = await Promise.all([
          catalogApi.getHighSchools(),
          catalogApi.getEduFormCycles(),
          catalogApi.getDiplomaTypes(),
          catalogApi.getSpecialityTableByEduLevel(1),
          catalogApi.getSpeciality(2),
          catalogApi.getSpeciality(3),
          catalogApi.getSpecialityTable()

        ])

        this.highSchools = list
        this.eduFormCycles = list2
        this.diplomaTypes = list3
        this.specialitySpc = list4
        this.specialityIntOrd = list5
        this.specialityAsp = list6
        this.specialityDpo = list7

        this.$emit('dataLoaded')
      } catch (e) {
        console.log(e.status)
      }
    },

    setValidStatus() {
      this.$emit('setValidStatus', !this.$v.$invalid)
    }
  },

  watch: {
    data: {
      immediate: true,
      handler(val) {
        if (val) {
          if (!_isEqual(this.form, val)) {
            this.form = AddEducation.buildFromAPI(val)
          }
        } else {
          this.form = new AddEducation()
        }
      },
    },

    form: {
      deep: true,
      handler(data) {
        this.setValidStatus()
        this.$emit('updateAddEducationCard', data)
      }
    }
  }
}
</script>